

const SearchEngSec = () => {


    return (<>



        <div className="searchEngine section-home">

            <div className="cardsContainer-home searchCards">
                <h2 className="h2Home">A Powerful Food Delivery <span id="span2">Search Engine</span></h2>
                <p className="p-home">Search multiple food delivery apps at once. No need to hop between apps ever again.</p>
                <div className="searchCardsRow">
                    <div className="searchCard1 card-home">
                        <h5>A POWERFUL SEARCH</h5>
                        <h3>Mix & Match Your Search</h3>
                        <img src="/assets/web/searchEngineFirstCard.webp" alt=""></img>

                    </div>
                    <div className="searchCard2 card-home">
                        <img src="/assets/web/searchIcon.png" alt=""></img>
                        <h3>Search All Delivery Services With <span>One App</span></h3>
                        <p>Doordash, Ubereats, Grubhub, Seamles, and & Postmates in One Search</p>
                        <p className="mobileCardText">Doordash, Ubereats, Grubhub & More In One Search.</p>
                    </div>
                </div>
            </div>


        </div>


    </>
    );
}

export default SearchEngSec;