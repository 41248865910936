const Footer = () => {
    return(
        <>
        <footer>
            <h1 className="vivify fadeIn duration-400 delay-800">Let’s Make Food Delivery <span>Easy</span></h1>
            <p className="vivify fadeIn duration-400 delay-800">We’ll handle everything. You just get ready to find restaurants you’ve never seen before.</p>
            <a href="https://apps.apple.com/us/app/foodscout-compare-fees/id6449804396" target="_blank"><img src="/assets/web/footerPics/footerDownload.svg" alt="download"></img></a>
            <div className="marquee">
                <div className="marqueeContent">
                    <img src="/assets/web/footerPics/connectservices1.webp" alt=""></img>
                    <img src="/assets/web/footerPics/home2.webp" alt=""></img>
                    <img src="/assets/web/footerPics/allinonesearch1.webp" alt=""></img>
                    <img src="/assets/web/footerPics/deliveryfees1.webp" alt=""></img>
                    
                    <img src="/assets/web/footerPics/connectservices1.webp" alt=""></img>
                    <img src="/assets/web/footerPics/home2.webp" alt=""></img>
                    <img src="/assets/web/footerPics/allinonesearch1.webp" alt=""></img>
                    <img src="/assets/web/footerPics/deliveryfees1.webp" alt=""></img>
                </div>
            </div>


            
        </footer>
        
        
        
        </>




    )
}

export default Footer