import { Link } from "react-router-dom";


const OneAppSec = () => {

    return (<>

        <div className="oneApp section-home">

            <div className="cardsContainer-home appCards">
                <h2 className="h2Home">All Your Food Delivery In <span id="span3">One App</span></h2>
                <p className="p-home">Sign In and connect all favorite delivery apps. Place your order directly with FoodScout so feel free to delete all your other apps.</p>

                <div className="appCard1 card-home cardLeftP">
                    <div className=" card6Text cardText-home">
                        <h5>ONE APP FOR ALL DELIVERY</h5>
                        <h4>Easily <span>Signin</span> & Connect Your <span>Apps</span></h4>
                        <p>The more services you connect the more options you’ll have with FoodScout. No need to have any accounts with these services you can signup with FoodScout.</p>
                        <p className="mobileCardText">Connect Doordash, Grubhub, UberEats, Seamless & Postmates</p>
                    </div>

                    <img src="/assets/web/appCardsPhone.webp" alt=""></img>
                </div>
                <div className="appCardsRow2">
                    <div className="appCard2 card-home cardLeftP">
                        <img src="/assets/web/cartIcon.png" alt=""></img>

                        <div className="card7Text cardText-home">
                            <h5>ONE APP FOR ALL DELIVERY</h5>
                            <h4>Place <span>Your Order</span> Directly In The App</h4>
                            <p>Supports Doordash, Grubhub, UberEats, Seamless & Postmates</p>
                        </div>

                    </div>

                    <div className="appCard3 card-home cardText-home cardLeftP">
                        <h4>Let’s Make Food Delivery <span>Easy</span></h4>
                        <p>We’ll handle everything. You just get ready to find restaurants you’ve never seen before.</p>
                        <Link to="https://apps.apple.com/us/app/foodscout-compare-fees/id6449804396" target="_blank"><p>Download Now</p></Link>
                    </div>
                </div>




            </div>

        </div>




    </>
    );


}
export default OneAppSec;