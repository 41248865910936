

const CompareFoodSec = () => {

    return (<>

        <div className="compareFood section-home">


            <div className="cardsContainer-home compareCards">
                <h2 className="h2Home"><span id="span1">Compare Food</span> Delivery Apps In Real-time</h2>
                <p className="p-home">FoodScout automatically compares food delivery fees, delivery times, and menu prices across the top 5 major food delivery services in U.S.</p>

                <div className="compareCard1 card-home cardLeftP">
                    <div className="card1Text cardText-home">
                        <h5>COMPARE DELIVERY APPS</h5>
                        <h4>Get The Lowest Delivery Fees</h4> {/* margin? */}
                        <p>Delivery fees are influenced by many different conditions and FoodScout account for all of them. Automatically pulling delivery fee information in real-time.</p>
                    </div>

                    <img src="/assets/web/compareFood1stCard.webp" alt=""></img>
                </div>
                <div className="compareCardsRow2">{/* order of the 2 cards */}
                    <div className="compareCard2 card-home cardText-home cardLeftP">
                        <p><span id="card2text1">Did you know that </span><span id="card2text2">Menu Prices Can Be Different Depending on the App.</span><br></br>Always get the lowest menu price with FoodScout.</p>
                        <img src="/assets/web/compareFood2ndCard.webp" alt=""></img>   {/* why no scaling with height and not width?? */}
                    </div>
                    <div className="compareCard3 card-home">
                        <img src="/assets/web/lightningPic.svg" alt=""></img>
                        <div className="card3Text cardText-home cardLeftP">
                            <h5>COMPARE DELIVERY APPS</h5>
                            <h4>Get The Fastest Delivery Times</h4>
                            <p>Compare delivery times across multiple delivery apps.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </>)


}

export default CompareFoodSec;