import { useState } from "react"
import { Link } from "react-router-dom"
import { useEffect } from "react"

const NavBar = () => {

    const [state, setState] = useState()
    const [dropdown, setDropdown] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggle = () => {
        setDropdown(dropdown => !dropdown);
    };

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            <nav className={scrolled ? "scrolled-nav" : ""}>
                <Link to="/"><img src="/assets/web/appicon.png" id="logo" alt="logo" /><p>FoodScout</p></Link>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/cities">Cities</Link></li>
                    <li>
                        <a href="mailto:support@foodscoutapp.com">Email Us</a>
                    </li>
                    <li><Link to="https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396">Download Now</Link></li>

                </ul>
                <a href="https://apps.apple.com/us/app/foodscout-compare-fees/id6449804396" target="_blank" id="download"><img src="/assets/web/download.svg" alt="download"></img></a>
                <a href="https://apps.apple.com/us/app/foodscout-compare-fees/id6449804396" target="_blank" id="downloadMobile"><p>Get the App</p></a>
                <button onClick={toggle}><img src="/assets/web/Menu.svg"></img></button>
            </nav>
            <div className="mobileMenu" style={{ display: dropdown ? 'flex' : 'none' }}>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/cities">Cities</Link></li>
                    <li>            <a href="mailto:support@foodscoutapp.com">Email Us</a></li>
                    <li><Link to="https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396">Download Now</Link></li>

                </ul>
            </div>


        </>

    )
}

export default NavBar