import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LoopServerCall, MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler";
import NonLodable from "./nonLodable";
import OneAppSec from "./components/oneAppSec";
import CompareFoodSec from "./components/compareFoodSec";
import SearchEngSec from "./components/searchEngSec";
import NavBar from "./components/navbar";
import Cities from "./cities";
import Footer from "./components/footer";
const LandingPage = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <div className="mainPage">
                <NavBar />
                <div className="mainPageBackground"></div>

                <div className="circleAlign">
                    <span className="circles lightblue vivify fadeIn duration-600 delay-1500" id="circ1"></span>
                    <span className="circles lightblue vivify fadeIn duration-600 delay-1500" id="circ2"></span>
                    <span className="circles lightblue" id="circ3"></span>
                    <span className="circles green" id="circ4"></span>
                    <span className="circles darkblue" id="circ5"></span>
                    <span className="circles darkblue" id="circ6"></span>
                    <span className="circles purple" id="circ7"></span>
                    <span className="circles purple" id="circ8"></span>
                    <span className="circles lightblue vivify fadeIn duration-600 delay-1500" id="circ9"></span>
                    <span className="circles lightblue vivify fadeIn duration-600 delay-1500" id="circ10"></span>
                </div>

                <div className="mainContent">

                    <div className="hero">
                        <div className="heroContent vivify fadeIn duration-600 delay-300">
                            <h1 className="vivify fadeIn duration-400 delay-800">All Food Delivery in <span>One App</span></h1>
                            <p className="vivify fadeIn duration-400 delay-800">The only app that compares food delivery fees for <span>Doordash, Ubereats, Grubhub</span> and More.</p>
                            <img className="vivify fadeInBottom duration-600 delay-600" src="/assets/web/heroPic.webp" alt=""></img>
                        </div>
                    </div>

                    <CompareFoodSec />
                    <SearchEngSec />
                    <OneAppSec />

                </div>
                <Footer />
            </div>
        </>
    );
};

export default LandingPage;
